
@font-face {
    font-family: 'galanoclassic-boluploadedfile';
    src: url('#{$folder-fonts}galanoclassic-bold-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}galanoclassic-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'galanoclassic-meduploadedfile';
    src: url('#{$folder-fonts}galanoclassic-medium-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}galanoclassic-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'galanoclassic-semuploadedfile';
    src: url('#{$folder-fonts}galanoclassic-semibold-webfont.woff2') format('woff2'),
         url('#{$folder-fonts}galanoclassic-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


