/* ==========================================================================
   #heading-1
   ========================================================================== */
@mixin heading-1() {
    @include galanoclassic-semibold();
    color: $color-blue-light;
    line-height:1.2;
    
    @include media('>=tablet') {
        font-size: 35px;
    }

    @include media('<tablet') {
        font-size: 24px;
    }
}

/* ==========================================================================
   #heading-2
   ========================================================================== */
@mixin heading-2() {

    @include galanoclassic-semibold();
    color: $color-blue;
    line-height:1.2;

    @include media('>=tablet') {
        font-size: 25px;
    }

    @include media('<tablet') {
        font-size: 20px;
    }

}

/* ==========================================================================
   #heading-3
   ========================================================================== */
@mixin heading-3() {

    @include galanoclassic-semibold();
    color: $color-blue-light;

    @include media('>=tablet') {
        font-size: 26px;
    }

    @include media('<tablet') {
        font-size: 20px;
    }

}

/* ==========================================================================
   #heading-4
   ========================================================================== */
@mixin heading-4() {

    @include galanoclassic-semibold();
    color: $color-blue-light;

    @include media('>=tablet') {
        font-size: 24px;
    }

    @include media('<tablet') {
        font-size: 18px;
    }

}

/* ==========================================================================
   #heading-5
   ========================================================================== */
@mixin heading-5() {

    @include galanoclassic-semibold();
    color: $color-blue-light;

    @include media('>=tablet') {
        font-size: 22px;
    }

    @include media('<tablet') {
        font-size: 17px;
    }

}

/* ==========================================================================
   #heading-6
   ========================================================================== */
@mixin heading-6() {

    @include galanoclassic-semibold();
    color: $color-blue-light;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('<tablet') {
        font-size: 16px;
    }

}



.heading-1 {
    @include heading-1();
}

