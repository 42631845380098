
#{$feature-expertise} {
  @include padding-mobile-x();
  @include center-block();
  max-width:1120px;

  @include media('>=desktop') {
    margin-top:70px;
    margin-bottom:100px;
  }

  @include media('<desktop') {
    margin-top:50px;
    margin-bottom:50px;
  }

  .heading {
    @include heading-1();

    @include media('>=desktop') {
      margin-left:80px;
      position: relative;
      top:15px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .container-column {
    @include clearfix();

    .column {
      
      @include media('>=desktop') {
        float:left;
      }

      @include media('<desktop') {
      }

      &:last-child {
        margin-bottom:0;
      }

      &:nth-child(odd) {
        @include text-default();
        color:$color-white;
        background: $color-blue-dark;
        position: relative;

        @include media('>=desktop') {
          width:50%;
          padding:70px 60px;
          top:50px;
          left:50px;
        }

        @include media('<desktop') {
          padding:30px $padding-mobile-x;
        }

        h2,h3 {
          color:$color-white;
        }

      }

      &:nth-child(even) {
        @include text-default();
        color:$color-blue-dark;
        background: $color-gray-light;

        @include media('>=desktop') {
          width:50%;
          padding:90px 100px;
        }

        @include media('<desktop') {
          padding:30px $padding-mobile-x;
        }

        h2,h3 {
          color:$color-blue-dark;
        }


        strong {
          color:$color-blue-dark;
        }

      }

      strong {
        @include heading-3();
        display: inline-block;
        margin-bottom:14px;
      }

      h2,h3 {
        margin-bottom:30px;

        @include media('<desktop') {
          margin-bottom:15px;
        }

      }

      h2 {
        @include heading-2();
      }

      h3 {
        @include heading-3();
      }   

      ul {
        @include list-dash();
      }

      p {
        line-height:2;
        margin-bottom:20px;

        &:last-child {
          margin-bottom:0;
        }

        &:empty {
          display:none;
        }


      }

    }

  }

}