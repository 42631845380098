/*BLOC Nous joindre*/
.nous-joindre{

    .promotions-box {
      @include center-block();
      max-width:960px;
      margin-top:30px;
      padding:0 20px;
    }

    .row {
      @include clearfix();
    }

    .col-a,
    .col-b {

      h1{
        color: $color-blue;
        font-size: 2.250em;
        font-family: $color-blue-light;
        margin-bottom: 55px;
      }
      h2{
        color: #414141;
        font-size: 1.875em;
        font-family: $galanoclassic-medium;
        margin-top: 0;
        margin-bottom:20px;
      }

    }

    .col-a {

      @include media('>=tablet') {
        lost-column: 4/12 2 30px;
      }

    }

    .col-b {

      @include media('>=tablet') {
        lost-column: 8/12 2 30px;
      }

    }


	.wrapper{


		.column{
      
			.contact-nom {
				padding-left: 5px;
				color: #414141;
				margin-bottom: 5px;
				font-size: 1em;
				font-family: $galanoclassic-semibold; 
				&:before{
					content: url('../images/pin.png');
					position: relative;
					top: 13px;
					left: -5px;
				}
			}

			.contact-tel{
        display: block;
				color: #414141;
        margin-top: 15px;
				margin-bottom: 15px;
				font-size: 1em;
				font-family: $galanoclassic-semibold;
				&:before{
					content: url('../images/phone.png');
					position: relative;
					top: 3px;
				}
			}
			.contact-adresse{
				padding-left: 25px;
				color: #414141;
				font-size: 0.938em;
				font-family: $galanoclassic-medium;
			}
			dl{
				padding-left: 20px;
				margin-top: 10px;
				dt{
					font-size: 0.875em;
					font-family: $galanoclassic-medium;
					color: #414141;
					font-weight: 400;
				}
				dd{
					font-size: 0.875em;
					font-family: $galanoclassic-semibold;
					color: #414141;
					margin-bottom: 10px;
				}
			}
			input, textarea{
				margin-bottom: 15px;
				color: #6a6a6a;
				font-size: 0.875em;
				background-color:#f4f4f4  ;
			}
			input[type="text"]{
				height: 42px;
			}
			input[type="submit"]{
				margin:20px 0 0 0!important;
				font-family: $galanoclassic-semibold;
				@extend .btn--blue-light;
				@extend .btn--med;
				font-style: normal;
			}
			input[type="checkbox"]{
				width: 15px;
				height: 15px;
				position: relative;
				top: 3px;
				margin-right: 5px;
			}
			span.infolettre{
				left: -8px;
				label{
					color: #414141;
					font-size: 0.875em;
					font-family: $galanoclassic-medium; 
					font-weight: 400;
					line-height: 14px;
				}
			}
			textarea{
				height: 160px;
			}
			.contact-emploi{
				padding-left: 18px;
				font-family: $galanoclassic-semibold;
				font-size: 0.813em;
				text-transform: uppercase;
				a{
					color: $color-blue;
				}
				hr{
					background: 1px solid black;
					height: 1px;
					margin-top: 10px;
					margin-bottom: 15px;
					opacity: 0.2;
				}
			}
		}
	}	
}

@media screen and (max-width: 767px){
	.nous-joindre{
		padding-top: 25px;
		padding-bottom: 25px;
		&.container{
			.wrapper{
				h1{
					margin-bottom: 25px;
				}
				h2{
					font-size: 1.7em;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 979px){
	
}