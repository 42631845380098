a {
    transition: all 0.1s ease;
}

.image-bordered {
    border:8px solid $color-blue-light;
}

.img-container {
    .fluid-width-video-wrapper,
    img {
        @include center-block();
        max-width: 930px;
        border: 8px solid $color-blue-light;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.post-navigation {
    margin: 20px 0;
    text-align: center;
}

.wpcf7 {
    .column-droite,
    .column-gauche {
        lost-column: 1/2 2 30px;

        .col-sm-6 {
          lost-column: 1/2 2 10px;
        }

    }

    .column-bas {
      width:100%;
      overflow: hidden;
    }

    .column-droite {
      margin-top:52px;
    }


    .wpcf7-select,
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857;
        color: #555;
        background-color: #fff;
        background-image: none;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    .wpcf7-form-control-wrap {
        display: block;
    }

    .wpcf7-submit.btn {
        margin:20px 0 0 0;
        font-family: $galanoclassic-semibold;
        @extend .btn--blue-light;
        @extend .btn--med;
        font-style: normal;
    }

}

.demande-estimation .wrapper input,
.demande-estimation .wrapper select,
.demande-estimation .wrapper textarea {
    margin-bottom: 15px;
    color: #6a6a6a;
    font-family: Hind-Light, Helvetica, Arial, sans-serif;
    font-size: .875em;
}

.demande-estimation .wrapper textarea {
    height: 160px;
}

div.wpcf7 {
    margin: 0;
    padding: 0;
}

div.wpcf7-response-output {
    margin: 2em 0.5em 1em;
    padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0;
}

div.wpcf7-mail-sent-ok {
    border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
    border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
    border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
    border: 2px solid #ff0000!important;
    background-color:#fff!important;
}

.wpcf7-form-control-wrap {
    position: relative;
}

span.wpcf7-not-valid-tip {
    color: #f00;
    font-size: 1em;
    display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 100;
    border: 1px solid #ff0000;
    background: #fff;
    padding: .2em .8em;
}

span.wpcf7-list-item {
    margin-left: 0.5em;
}

.wpcf7-display-none {
    display: none;
}

div.wpcf7 img.ajax-loader {
    border: none;
    vertical-align: middle;
    margin-left: 4px;
}

div.wpcf7 div.ajax-error {
    display: none;
}

div.wpcf7 .placeheld {
    color: #888;
}

