#{$feature-services} {
  @include padding-mobile-x();
  @include clearfix();
  @include center-block();
  max-width:1120px;

  @include media('>=desktop') {
    margin-top:120px;
    margin-bottom:60px;
  }

  @include media('<desktop') {
    margin-top:30px;
    margin-bottom:30px;
  }

  .column {
    border:4px solid $color-blue-light;

    position: relative;

    @include media('>=desktop') {
      float:left;
      width:50%;
      height:350px;
    }

    @include media('<desktop') {
      padding-top:200px;
      margin-bottom:20px;

      &:last-child {
        margin-bottom:0;
      }

    }

    &:nth-child(n+1) {
      z-index:10;

      .box {
        
        @include media('>=desktop') {
          left:50px;
          top:-50px;
        }

        @include media('<desktop') {
            
        }

      }

      .btn {

        @include media('>=desktop') {
          left:100px;
        }

        @include media('<desktop') {}

      }


    }

    &:nth-child(n+2) {
      z-index:1;

      @include media('>=desktop') {
        margin-top:40px;
        margin-left:-20px;
      }

      @include media('<desktop') {
}
      .box {

        @include media('>=desktop') {
          top:-40px;
          left:120px;
        }

        @include media('<desktop') {
            
        }


      }

      .btn {
       
        @include media('>=desktop') {
          left:-70px;
        }

        @include media('<desktop') {}

      }

    }

  }

  .box {
    color:$color-white;
    background-color:$color-blue-dark;
    position: relative;

      @include media('>=desktop') {
        padding:30px 40px;
        max-width:360px;
      }

      @include media('<desktop') {
        padding:20px;
      }

  }

  .heading {
    @include heading-3();
    line-height:1.1;
    margin-bottom:20px;
    color: $color-white;
  }

  .text {
    @include text-default();
    margin-bottom:24px;
  }

  .btn {
    position: relative;
  }

}