#{$distinctions} {
  @include clearfix();
  @include center-block();
  max-width:930px;
  position: relative;

  @include media('<desktop') {
    @include padding-mobile-x();
    margin-bottom:40px;
  }

  .heading {
    @include heading-1();
    line-height:1.2;

    @include media('>=desktop') {
      width:340px;
      position:absolute;
      top:0;
      left:0;
    }

    @include media('<desktop') {
      text-align: center;
      margin-bottom:20px;
    }

  }

  .swiper-container {

    @include media('>=desktop') {
      width:720px;
      height:380px;
      float: right;
    }

    @include media('<desktop') {
      @include center-block();
    }

  }

  .swiper-slide img {

    @include media('>=desktop') {
      float: right;
      position: relative;
      top:10px;
      right:60px;
      z-index: 2;
    }

    @include media('<desktop') {}

  }

  /* text */
  .box {
    overflow: hidden;
    position: relative;
    
    @include media('>=desktop') {
      padding-bottom:60px;
    }

    @include media('<desktop') {
      @include center-block();
      max-width:300px;
      width:100%;
      padding-left:40px;
      padding-right:40px;
    }

    &:before {
      content:"";
      width:60px;
      height:320px;
      display: block;

      position: absolute;
      top:30px;
      right:0;

      background-color:$color-blue-light;
    }

    &:after {
      content:"";
      width:600px;
      height:60px;
      display: block;

      position: absolute;
      bottom:0;
      right:0;
      z-index: 1;

      background-color:$color-blue-light;
    }

    &:before,
    &:after {

      @include media('>=desktop') {}

      @include media('<desktop') {
        display:none;
      }

    }

  }

  .content {
    color:$color-white;
    background-color:$color-blue-dark;
    font-size:18px;

    @include media('>=desktop') {
      padding:20px 34px;
      width:300px;
      position: absolute;
      bottom:30px;
      left:0;
      z-index:1;
    }

    @include media('<desktop') {
      padding:20px;
    }

    .link {
      font-size: 11px;
      font-style: italic;
      text-decoration: underline;
    }

  }

  /* button nav */
  .swiper-buttons {

    @include media('>=desktop') {
      width:80px;
      height:30px;

      position: absolute;
      bottom: 12px;
      left: 150px;
      z-index:11;
    }

    @include media('<desktop') {
   
    }


  }

  .swiper-button-next,
  .swiper-button-prev {
    background:none;
    top:0;
    height:30px;
    margin-top:0;

    @include media('<desktop') {
      top:50%;
      transform: translateY(-50%);
    }

    &:before {
      font-size:30px;
    }

  } 

  .swiper-button-next {
    
    right:0;

    &:before {
      @include icon-css($icon-arrow-right);
    }

  }

  .swiper-button-prev {
    left:0;

    &:before {
      @include icon-css($icon-arrow-left);
    }

  }

  .swiper-pagination {
    color:$color-blue-light;
    position: absolute;
    bottom: -25px;
    
    z-index:1000;
    font-size:12px;

    @include media('>=desktop') {
      left: -116px;
    }

    @include media('<desktop') {
      left:0;
    }


  }

}