/* ==========================================================================
   #icon-css
   ========================================================================== */
@mixin icon-css($content: false) {

  @if $content {
    content: '#{$content}';
  }
  
  display: block;
  font-family: 'adeleicon' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




