/* ==========================================================================
   #ANIMATION
   ========================================================================== */

@keyframes fadeIn {

  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;

  }

}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes fadeOut {

  0% {
    opacity: 1;

  }

  100% {
    opacity: 0;

  }

}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes spin-loader {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}

.spin-loader {
  animation-name: spin-loader;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-fill-mode: both;
}
