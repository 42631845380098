.page-promotions {
  
  .img-container {
    @include center-block();
    max-width:930px;
    position: relative;

    @include media(">=large") {
      margin-bottom:150px;
    }

    @include media("<large") {
      @include padding-mobile-x();
      margin-bottom:20px;
    }

    img {
      max-width:100%;
      width: auto;
    }


  }


  .box-promo {
    background-color:$color-blue-dark;
    color:$color-white;

    @include media(">=large") {
      padding:40px 40px 60px 40px;
      position:absolute;
      bottom:-50px;
      right:-20px;
      width:420px;

    }

    @include media("<large") {
      padding:30px 20px;
    }

    .content {
      font-size:15px;

      p {
        margin-bottom:5px;
      }

    }

    .btn-print {

      @include media(">=large") {
        position: absolute;
        bottom:-20px;
        right:20px;
      }

      @include media("<large") {
        margin-top:20px;
      }

    }

  }


}

