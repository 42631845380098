@import "variables";

@font-face {
  font-family: 'adeleicon';
  src:  url('#{$icomoon-font-path}/adeleicon.eot?8f9v4z');
  src:  url('#{$icomoon-font-path}/adeleicon.eot?8f9v4z#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/adeleicon.ttf?8f9v4z') format('truetype'),
    url('#{$icomoon-font-path}/adeleicon.woff?8f9v4z') format('woff'),
    url('#{$icomoon-font-path}/adeleicon.svg?8f9v4z#adeleicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'adeleicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-right {
  &:before {
    content: $icon-chevron-thin-right;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-arrow-small-down {
  &:before {
    content: $icon-arrow-small-down;
  }
}
.icon-arrow-small-up {
  &:before {
    content: $icon-arrow-small-up;
  }
}
.icon-arrow-small-right {
  &:before {
    content: $icon-arrow-small-right;
  }
}
.icon-arrow-small-left {
  &:before {
    content: $icon-arrow-small-left;
  }
}
.icon-chevron-thin-left {
  &:before {
    content: $icon-chevron-thin-left;
  }
}
.icon-chevron-thin-up {
  &:before {
    content: $icon-chevron-thin-up;
  }
}
.icon-chevron-thin-down {
  &:before {
    content: $icon-chevron-thin-down;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

