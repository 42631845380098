$icomoon-font-path: "../fonts" !default;

$icon-chevron-thin-right: "\e900";
$icon-linkedin: "\e901";
$icon-arrow-small-down: "\e905";
$icon-arrow-small-up: "\e906";
$icon-arrow-small-right: "\e907";
$icon-arrow-small-left: "\e908";
$icon-chevron-thin-left: "\e909";
$icon-chevron-thin-up: "\e90a";
$icon-chevron-thin-down: "\e90b";
$icon-arrow-down: "\e90c";
$icon-arrow-right: "\e90d";
$icon-arrow-left: "\e90e";
$icon-arrow-up: "\e90f";
$icon-youtube: "\e910";
$icon-instagram: "\e911";
$icon-facebook: "\e912";



