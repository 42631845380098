
.layout-about-service-blocks {
    position: relative;

    @include media('>=desktop') {
        margin-top:-20px;
    }

    &__a,
    &__b,
    &__c {

        h2 {

            @include heading-2();
            color:$color-blue-dark;

            @include media('>=desktop') {
                margin-bottom:30px;
            }

            @include media('<desktop') {
                margin-bottom:15px;
            } 

        }

        p {
            line-height:2;
            font-size:14px;

            margin-bottom:30px;

            &:last-child {
                margin-bottom:0;
            }
        }

    }

    &__a {
        background-color:$color-gray-light;
        position: relative;
        z-index:20;

        @include media('>=desktop') {
            width:560px;
            padding:80px;
        }

        @include media('<desktop') {
            padding:30px 20px;
        }  

    }

    &__b {
        background-color:$color-blue-dark;
        color:$color-white;

        position: relative;
        z-index:10;

        @include media('>=desktop') {
            width:560px;
            padding:80px 50px 80px 160px;
            margin:-440px 0 0 440px;
        }

        @include media('<desktop') {
            padding:30px 20px;
        }

        h2 {
            @include heading-2();
            color:currentColor;
        }

    }

    &__c {

        background-color:$color-blue-dark;
        color:$color-white;

        position: relative;
        z-index:10;

        @include media('>=desktop') {
            width:960px;
            margin:-60px 0 0 40px;
            padding:60px 50px 80px 120px;
        }

        @include media('<desktop') {
            padding:0 20px 30px 20px;
        }

    }
}

.layout-about-service {

    @include media('<desktop') {
        @include padding-mobile-x();
    }

    &__img-a {
        position:relative;
        z-index:30;

        @include media('>=desktop') {
            margin:-60px 0 0 -100px;
        }

        @include media('<desktop') {
            @include center-block();
            margin-top:20px;
            margin-bottom:20px;
        }

    }

    &__img-b {
        position:relative;
        z-index:1;

        @include media('>=desktop') {
            margin:-110px 0 0 220px;
        }

        @include media('<desktop') {
            @include center-block();
            margin-bottom:20px;
        }

    }

}