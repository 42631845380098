/* ==========================================================================
   #CONFIG
   ========================================================================== */

$env : 'prod';

/* ==========================================================================
   #FOLDER
   ========================================================================== */

$folder-fonts:      "../fonts/";
$folder-images:     "../images/";
$folder-svg:        "../svg/";
$icomoon-font-path: $folder-fonts;

/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

$galanoclassic-bold : 'galanoclassic-boluploadedfile', arial;
$galanoclassic-semibold : 'galanoclassic-semuploadedfile', arial;
$galanoclassic-medium : 'galanoclassic-meduploadedfile', arial;

$font-heading: $galanoclassic-semibold;
$font-heading-line-height : 1.4;

$font-text:    $galanoclassic-medium;
$font-text-line-height : 1.4;

$map-font-sizes-heading: (
  'h1':  40px,
  'h2':  32px,
  'h3':  30px,
  'h4':  28px,
  'h5':  26px,  
  'h6':  20px 
);

$map-font-sizes-text: (
  'mini':   8px,
  'tiny':   10px,
  'small':  12px,
  'medium': 16px,
  'large':  24px,
  'big':    40px,
  'huge':   80px,
);

$font-weight-light:   	300;
$font-weight-regular: 	400;
$font-weight-bold:    	700;
$font-weight-black:   	700;

/* ==========================================================================
   #COLORS
   ========================================================================== */

$color-white:       #fff;
$color-black:       #000;

$color-gray-light: #efefef;

$color-blue-very-light: #90c5d0;
$color-blue-light:    #579eac;
$color-blue:          #6e929a;
$color-blue-dark:     #3e4855;


// Typo
$color-text:        $color-white;
$color-heading:     $color-black;

// State
$color-valid:       green;
$color-error:       red;
$color-warning:     yellow;

// Text selection
$text-selection-color : #000;
$text-selection-bg : #b3d4fc;

/* ==========================================================================
   #FORM
   ========================================================================== */

$form-border-width:           0px;
$form-border-color:           #ccc;
$form-border-radius:          0;

$form-input-height:           40px;
$form-input-color:            #5a5657;
$form-input-bgcolor:          #e6e1db;
$form-input-placehoder-color: #888;
$form-input-padding-x:        12px;
$form-input-padding-y:        8px;

/* ==========================================================================
   #MEDIA-QUERIES
   ========================================================================== */

$breakpoints: (
  'small':   320px,
  'phone':   520px,
  'tablet':  768px,
  'desktop': 1024px,
  'large':   1200px,  
);

$padding-mobile : 20px;
$padding-mobile-x : 20px;
$padding-mobile-y : 20px;

/* ==========================================================================
   #CONTAINER
   ========================================================================== */

$map-containers: (
  'mini':    320px,
  'tiny':    600px,
  'small':   700px,
  'medium':  900px,
  'large':   960px,
  'big':     1040px,
  'bigplus': 1040px,
  'max':     1200px,
  'full':    100%,  
);

/* ==========================================================================
   #Z-INDEX
   ========================================================================== */

$z-sticky-nav: 100;
$z-mobile-nav: 100;
$z-overlay:    99;
$z-modal:      101;

/* ==========================================================================
   #TRANISTION
   ========================================================================== */
   
$transition-speed-slow:   0.6s;
$transition-speed-medium: 0.3s;
$transition-speed-fast:   0.1s;

