#{$feature-hero}-home.right {
  @include clearfix();
  @include center-block();
  max-width:960px;
  position: relative;

  @include media('>=desktop') {
    margin-top:160px;
    margin-bottom:60px;
    min-height: 400px;
  }

  @include media('<desktop') {
    @include padding-mobile-x();
    margin-top:30px;
    margin-bottom:30px;
  }

  &:before {

    @include media('>=desktop') {
      content:"";
      display: block;
      width:650px;
      height:100%;
      position: absolute;
      top:0;
      right:-50px;
    }
  }

  .box {
    position: relative;

    @include media('>=desktop') {
      float: right;
      width:300px;
      text-align: right;
      position: relative;
      top:-20px;
      margin-right: 0;
    }

    @include media('<desktop') {
      padding-bottom:80px;
    }

  }

  .heading {
    @include heading-2();
    color: $color-blue-dark;
    

    @include media('>=desktop') {
      margin-bottom:20px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .text {
    @include text-default();
    line-height:2;

    p {
      margin-bottom:20px;

      &:last-child {
        margin-bottom:0;
      }
    }


  }

  .btn {
    margin-top:20px;
  }

  .image {

    @include media('>=desktop') {
      position: absolute;
      top:-20px;
      left:0;
      z-index:10;
    }

    @include media('<desktop') {
      margin-bottom:40px;
    }

    &:after {

      @include media('>=desktop') {
        content: "";
        width: 420px;
        height: 360px;
        position: absolute;
        top: 20px;
        right: -12px;
        z-index: 1;
        background-color: #3e4855;
      }

      @include media('<desktop') {

      }


    }

    img {
      border:4px solid $color-blue-light;
      display: block;
      position: relative;
      z-index:100;

      @include media('>=desktop') {}

      @include media('<desktop') {
        @include center-block();
      }
    }

    .btn {

      @include media('>=desktop') {
        position: absolute;
        z-index:100;
        bottom: -46px;
        right:0;
      }

      @include media('<desktop') {
        position: absolute;
        z-index:100;
        bottom:0;
        left:20px;
      }


    }

  }

}

#{$feature-hero}-home.left {
  @include clearfix();
  @include center-block();
  max-width:960px;
  position: relative;

  @include media('>=desktop') {
    margin-top:160px;
    margin-bottom:60px;
    min-height: 400px;
  }

  @include media('<desktop') {
    @include padding-mobile-x();
    margin-top:30px;
    margin-bottom:30px;
  }

  &:before {

    @include media('>=desktop') {
      content:"";
      display: block;
      width:650px;
      height:100%;
      position: absolute;
      top:0;
      left:-50px;
    }
  }

  .box {
    position: relative;

    @include media('>=desktop') {
      float: left;
      width:300px;
      text-align: left;
      position: relative;
      top:-20px;
      margin-right: 40px;
    }

    @include media('<desktop') {
      padding-bottom:80px;
    }

  }

  .heading {
    @include heading-2();
    color: $color-blue-dark;
    

    @include media('>=desktop') {
      margin-bottom:20px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .text {
    @include text-default();
    line-height:2;

    p {
      margin-bottom:20px;

      &:last-child {
        margin-bottom:0;
      }
    }


  }

  .btn {
    margin-top:20px;
  }

  .image {

    @include media('>=desktop') {
      position: absolute;
      top:-40px;
      right:0;
      z-index:10;
    }

    @include media('<desktop') {
      margin-bottom:40px;
    }

    &:after {

      @include media('>=desktop') {
        content: "";
        width: 420px;
        height: 360px;
        position: absolute;
        top: 20px;
        left: -12px;
        z-index: 1;
        background-color: #3e4855;
      }

      @include media('<desktop') {

      }


    }

    img {
      border:4px solid $color-blue-light;
      display: block;
      position: relative;
      z-index:100;

      @include media('>=desktop') {}

      @include media('<desktop') {
        @include center-block();
      }
    }

    .btn {

      @include media('>=desktop') {
        position: absolute;
        z-index:100;
        bottom: -46px;
        left:0;
      }

      @include media('<desktop') {
        position: absolute;
        z-index:100;
        bottom:0;
        left:20px;
      }


    }

  }

}