/* ==========================================================================
   #BLOCKQUOTES-SECTION
   ========================================================================== */

// Variables
/////////////////////////////

#{$blockquotes-section} {
  @include center-block();
  max-width:1120px;
  position: relative;
  background: $color-blue-light url('/wp-content/uploads/bg-testimonials.jpeg') center/cover no-repeat;
  color:$color-blue-dark;

  @include media('>=tablet') {
    margin-top:80px;
    padding:60px 40px 80px 40px;
  }

  @include media('<tablet') {
    margin-top:40px;
    padding:20px 20px 40px 20px;
    height:auto;
  }

  .swiper-container-blockquote {
    @include center-block();
    max-width:740px;
    text-align: center;

    &:before,
    &:after {
      @include center-block();
      content:"";
      width:75px;
      height:60px;

      @include media('<tablet') {
        display:none!important;
      }

    }
    
    &:before {
      background: url('../images/quote-start.png') 0 0 no-repeat;
      background-size: 100% auto;
    }

    &:after {
      background: url('../images/quote-end.png') 0 0 no-repeat;
      background-size: 100% auto;
    }

  }

  .swiper-wrapper {
    padding:24px 0;
    align-items: center;
  }

  .swiper-slide {

    .text {
      font-size: 24px;
      line-height: 1.4;

      @include media('<tablet') {
        font-size: 24px;
      }

      &:before {
        font-size:18px;
      }

    }

  }


  .swiper-button-next,
  .swiper-button-prev {
    background:none;

    @include media('<tablet') {
      display:none!important;
    }

    &:before {
      font-size:30px;
    }

  } 

  .swiper-button-next {
    
    right:40px;

    &:before {
      @include icon-css($icon-arrow-right);
    }

  }

  .swiper-button-prev {
    left:40px;

    &:before {
      @include icon-css($icon-arrow-left);
    }

  }

  .swiper-pagination {
    bottom: 30px;
    left: 0;
    width: 100%;

    .swiper-pagination-bullet {
      opacity:1;
      width:14px;
      height:14px;
      border:2px solid $color-white;
      background-color:transparent;
      margin-right:18px;

      &:last-child {
        margin-right:0;
      }


    }

    .swiper-pagination-bullet-active {
      background-color:$color-white;
    }

  }
  

}

/* ==========================================================================
   #WYSIWYG-MODIFIER
   ========================================================================== */

/* ==========================================================================
   #WYSIWYG-STYLE
   ========================================================================== */