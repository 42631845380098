/*BLOC About us*/

.dit-nous {
    .ajax-tabs {
        @include center-block();
        max-width: 960px;
    }
  
    .description {
        p {
            color: #414141;
            margin-bottom: 40px;
        }
    }
    /* sorting list styles */
    .filtre {
        margin-top: 40px;
    }
    .sorting-posts {
        list-style-type: none;
        margin-bottom: 40px;
        text-align: center;
        li {
            display: inline-block;
            font-family: $galanoclassic-semibold;
            font-size: 0.813em;
            margin: 0 5px 10px 0;
            padding: 0;
            a {
                background-color: #e0e0e0;
                color: #414141;
                display: inline-block;
                min-width: 142px;
                padding: 12px 35px;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                &.active,
                &:hover {
                    color: white;
                    background-color: $color-blue-dark;
                }
            }
        }
    }
    /* results by sorting */
    .ajax-tabs-loading .ajax-holder {
        background: url(../images/loader.gif) no-repeat 50% 50%;
        min-height: 500px;
    }
    .results-sorting {
        margin-top: 40px;
        padding: 0;
        overflow: hidden;
        .box {
            width: 25%;
            display: inline-block;
            vertical-align: top;
            letter-spacing: normal;
            padding: 0 18px 18px 0;
            .lienPrincipal {
                text-decoration: none;
                color: inherit;
            }
            &.audio,
            &.newspaper {
                img {
                    width: 100%;
                    display: block;
                    border-radius: 2px 2px 0 0;
                    position: relative;
                }
            }
            &.videos,
            &.radio {
                img {
                    width: 100%;
                    display: block;
                    border-radius: 2px 2px 0 0;
                    top: 0 !important;
                }
            }
            .holder {
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 31px 21px 1px;
                position: relative;
                line-height: 15px;
                background-color: #f2f2f2;
            }
        }
        .box-images {
            margin: -31px -21px 12px;
            /*padding: 30px 25px;*/
            position: relative;
            background-color: white;
            text-align: center;
            overflow: hidden;
            padding: 20px;
        }
        time {
            color: #aeaeae;
            font-size: 0.688em;
            font-family: $galanoclassic-semibold;
            display: inline-block;
            margin-bottom: 15px;
            text-align: left;
        }
        h2 {
            text-transform: uppercase;
            margin-bottom: 8px;
            margin-top: 0;
            color: $color-blue-dark;
            font-size: 1em;
            font-family: $galanoclassic-semibold;
            &.item {
                text-transform: none;
            }
        }
        p {
            color: #333333;
            font-size: 0.750em;
            font-family: $color-blue;
            line-height: 20px;
        }
        blockquote {
            font-family: $color-blue-light;
            font-size: 1.063em;
            font-style: italic;
            padding: 0;
            margin: 0;
            border-left: none;
        }
        footer {
            margin-bottom: 25px;
            text-align: right;
            .button-red {
                margin: 0;
                color: $color-blue-dark;
                font-size: 0.813em;
                font-family: $galanoclassic-semibold;
                text-transform: uppercase;
            }
        }
    }
    .results-sorting .box.audio img {
        max-width: 100% !important;
        width: auto;
        height: auto;
        display: inline-block;
        vertical-align: top;
        border-radius: 0;
    }
    /*.results-sorting .audio img{margin:25px 0 -34px;}*/
    .modal-body img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    .results-sorting h2.item {
        margin: 0 0 16px;
    }
    .results-sorting h2 a {
        color: #363636;
        text-decoration: none;
    }
    .results-sorting h2 a:hover {
        color: #b0080c;
    }
    .results-sorting p {
        margin: 0 0 15px;
    }
    .results-sorting .item-title {
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
    }
    .btn-lightbox {
        background: #b0080c;
        color: #fefefe;
        position: absolute;
        left: 50%;
        bottom: -36px;
        margin-left: -39px;
        width: 80px;
        height: 80px;
        border-radius: 82px;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 10px;
        font-weight: bold;
        outline: none !important;
    }
    .btn-lightbox:after {
        content: '';
        display: inline-block;
        height: 64px;
        width: 1px;
        overflow: hidden;
        margin: 0 0 0 -5px;
        vertical-align: middle;
    }
    .btn-lightbox span {
        vertical-align: middle;
        display: inline-block;
        white-space: normal;
    }
    .btn-lightbox .icon-ico-photo {
        font-size: 30px;
        line-height: 30px;
        display: inline-block;
        vertical-align: top;
        margin: -5px 0 0 -4px;
    }
    .btn-lightbox .icon-ico-video {
        font-size: 36px;
        line-height: 36px;
        display: inline-block;
        vertical-align: top;
        margin: -3px 0 0 0;
    }
    /* 4. Custom form styles */
    .no-margin-form form {
        margin: 0;
    }
    /* hide original element */
    input[type="radio"].jcf-hidden,
    select.jcf-hidden {
        display: block !important;
        position: absolute !important;
        left: -9999px !important;
        /* change to right: -9999px on RTL sites */
    }
    input[type="checkbox"]+span {
        top: 3px;
    }
    /* custom radio styles */
    .rad-area {
        border-radius: 14px;
        background: #f0f0f0;
        margin: 0 10px 0 0;
        float: left;
        width: 13px;
        height: 13px;
        box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        -moz-box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
    .rad-checked {
        background: #d75054;
        background: -moz-linear-gradient(top, #d75054 0%, #cf4c4f 8%, #744a4b 75%, #62494a 92%, #604d4e 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d75054), color-stop(8%, #cf4c4f), color-stop(75%, #744a4b), color-stop(92%, #62494a), color-stop(100%, #604d4e));
        background: -webkit-linear-gradient(top, #d75054 0%, #cf4c4f 8%, #744a4b 75%, #62494a 92%, #604d4e 100%);
        background: -o-linear-gradient(top, #d75054 0%, #cf4c4f 8%, #744a4b 75%, #62494a 92%, #604d4e 100%);
        background: -ms-linear-gradient(top, #d75054 0%, #cf4c4f 8%, #744a4b 75%, #62494a 92%, #604d4e 100%);
        background: linear-gradient(to bottom, #d75054 0%, #cf4c4f 8%, #744a4b 75%, #62494a 92%, #604d4e 100%);
    }
    /* custom select styles */
    .select-area {
        border: 1px solid #c1c1c1;
        background: #faf9f5;
        position: relative;
        overflow: hidden;
        height: 23px;
        cursor: pointer;
        color: #666;
        width: 100% !important;
        float: left;
    }
    .select-area .center {
        white-space: nowrap;
        padding: 3px 10px;
        display: block;
    }
    .select-area .select-opener {
        background: url(../images/sprite.png) no-repeat -106px -96px;
        position: absolute;
        width: 17px;
        height: 21px;
        right: 0;
        top: 0;
        padding: 0;
    }
    .select-options {
        position: absolute;
        overflow: hidden;
        background: #fff;
        z-index: 2000;
    }
    .select-options .drop-holder {
        border: 1px solid #777;
        overflow: hidden;
        height: 1%;
    }
    .select-options ul a {
        text-decoration: none;
        padding: 5px 10px;
        display: block;
        cursor: pointer;
        color: #000;
        height: 1%;
    }
    .select-options .item-selected a {
        text-decoration: none;
        background: #007;
        color: #fff;
    }
    .select-quebec.select-area {
        background: #fff;
        height: 26px;
        box-shadow: inset 2px -3px 5px -3px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: inset 2px -3px 5px -3px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: inset 2px -3px 5px -3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        color: #7b0003;
        text-transform: none;
        width: auto !important;
        font-weight: 700;
        padding: 5px 0 0;
        border: none;
        font-size: 14px;
        line-height: 16px;
    }
    .select-quebec.select-area .center {
        padding: 0 25px 0;
        letter-spacing: 1px;
    }
    .select-quebec.select-area .select-opener {
        background: url(../images/sprite.png) no-repeat -168px -16px;
        height: 13px;
        width: 9px;
        right: 7px;
        top: 6px;
    }
    .select-options.drop-quebec {
        position: absolute;
        overflow: hidden;
        background: #eee;
        z-index: 2000;
        margin: -4px 0 0;
        border-radius: 0 0 4px 4px;
    }
    .select-options.drop-quebec .drop-holder {
        overflow: hidden;
        height: 1%;
        border-radius: 0 0 4px 4px;
        border: none;
    }
    .select-options ul {
        list-style: none;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
    .select-options ul li {
        width: 100%;
        float: left;
        padding: 0;
    }
    .select-options ul li:after {
        display: none;
    }
    .select-options.drop-quebec ul a {
        text-decoration: none;
        padding: 5px 10px;
        color: #7b0003;
    }
    .select-options.drop-quebec .item-selected a {
        background: #7b0003;
        color: #fff;
    }
    .box-informations .tell:hover {
        color: #666;
    }
    /* lightbox styles */
    .popup-holder {
        position: relative;
        overflow: hidden;
        height: 0;
    }
    .lightbox-box {
        /*padding: 66px 20px;*/
        width: 616px;
        min-height: 360px;
        border: 3px solid #797979;
        border-radius: 6px;
        background: #e8e4d8 url(../images/bg-page.png) 50% 0;
    }
    .lightbox-box .logo-box {
        display: block;
        margin: 0 auto 31px;
        text-align: center;
    }
    .lightbox-box .logo-box a {
        display: inline-block;
        vertical-align: top;
    }
    .lightbox-box .logo-box img {
        display: block;
        position: relative;
        top: 60px;
        z-index: 10;
        margin-left: 30px;
    }
    .lightbox-box .logo-box .ribbonBienvenue {
        height: 160px;
        width: 146px;
        left: 50px;
        background: url(../images/bg-submenu-center-168.png) no-repeat 0 -10px;
        position: relative;
    }
    .lightbox-box .logo-box .ribbonBienvenue p {
        color: white;
        font: 20px/24px 'baskerville_old_faceregular', 'times new roman', serif;
        position: relative;
        top: 59px;
        left: -5px;
    }
    .lightbox-box .logo-box .ribbonBienvenue p:nth-of-type(2) {
        border-top: 2px dashed #c79051;
        margin-top: 7px;
        padding-top: 7px;
        width: 120px;
        margin-left: 15px;
    }
    .lightbox-box .logo-box .ribbonBienvenue:after {
        content: '';
        background: url(../images/bg-submenu.png) no-repeat;
        width: 146px;
        height: 65px;
        position: absolute;
        left: 0;
        bottom: -54px;
        z-index: 0;
    }
    .lightbox-box .add-nav {
        height: 67px;
        padding: 0;
        padding-left: 200px;
        margin: 0;
        margin-top: -67px;
        list-style: none;
        text-align: center;
        font: 20px/67px 'baskerville_old_faceregular', 'times new roman', serif;
        background: url(../images/bg-title-mobile.png) no-repeat !important;
        background-size: 100% 100% !important;
    }
    .lightbox-box .add-nav li {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
    }
    .lightbox-box .add-nav li:after {
        content: '';
    }
    .lightbox-box .add-nav a {
        display: inline-block;
        vertical-align: top;
        color: #fff;
        text-decoration: none;
        padding: 2px 12px 0;
    }
    .lightbox-box .button-red:hover,
    .lightbox-box .active .button-red {
        color: #333;
    }
    .lightbox-box .add-nav a:after {
        display: none;
    }
    .lightbox-box .add-nav em {
        font-size: 16px;
    }
    .lightbox-box .add-nav em a {
        color: #333;
        padding: 0;
    }
    .lightbox-box .add-nav a:hover {
        text-decoration: underline;
    }
    #fancybox-loading {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin-top: -20px;
        margin-left: -20px;
        cursor: pointer;
        overflow: hidden;
        z-index: 1104;
        display: none;
    }
    #fancybox-loading div {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 480px;
        background-image: url(../images/fancybox/fancybox.png);
    }
    #fancybox-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1100;
        display: none;
    }
    #fancybox-tmp {
        padding: 0;
        margin: 0;
        border: 0;
        overflow: auto;
        display: none;
    }
    #fancybox-wrap {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        z-index: 1101;
        outline: none;
        display: none;
    }
    #fancybox-outer {
        position: relative;
        width: 100%;
        height: 100%;
    }
    #fancybox-content {
        width: 100% !important;
        height: 0;
        padding: 0;
        outline: none;
        position: relative;
        overflow: hidden;
        z-index: 1102;
        border: 0 solid #fff;
    }
    #fancybox-hide-sel-frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 1101;
    }
    #fancybox-close {
        position: absolute;
        top: -15px;
        right: -15px;
        width: 30px;
        height: 30px;
        background: transparent url(../images/fancybox/fancybox.png) -40px 0px;
        cursor: pointer;
        z-index: 1103;
        display: none;
    }
    #fancybox-error {
        color: #444;
        font: normal 12px/20px Arial;
        padding: 14px;
        margin: 0;
    }
    #fancybox-img {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        line-height: 0;
        vertical-align: top;
    }
    #fancybox-frame {
        width: 100%;
        height: 100%;
        border: none;
        display: block;
    }
    #fancybox-left,
    #fancybox-right {
        position: absolute;
        bottom: 0px;
        height: 100%;
        width: 35%;
        cursor: pointer;
        outline: none;
        background: transparent url(../images/fancybox/blank.gif);
        z-index: 1102;
        display: none;
    }
    #fancybox-left {
        left: 0;
    }
    #fancybox-right {
        right: 0;
    }
    #fancybox-left-ico,
    #fancybox-right-ico {
        position: absolute;
        top: 50%;
        left: -9999px;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        cursor: pointer;
        z-index: 1102;
        display: block;
    }
    #fancybox-left-ico {
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -30px;
    }
    #fancybox-right-ico {
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -60px;
    }
    #fancybox-left:hover span {
        left: 20px;
    }
    #fancybox-right:hover span {
        left: auto;
        right: 20px;
    }
    .fancybox-bg {
        position: absolute;
        padding: 0;
        margin: 0;
        border: 0;
        width: 20px;
        height: 20px;
        z-index: 1001;
    }
    #fancybox-bg-n {
        top: -20px;
        left: 0;
        width: 100%;
        background-image: url(../images/fancybox/fancybox-x.png);
    }
    #fancybox-bg-ne {
        top: -20px;
        right: -20px;
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -162px;
    }
    #fancybox-bg-e {
        top: 0;
        right: -20px;
        height: 100%;
        background-image: url(../images/fancybox/fancybox-y.png);
        background-position: -20px 0px;
    }
    #fancybox-bg-se {
        bottom: -20px;
        right: -20px;
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -182px;
    }
    #fancybox-bg-s {
        bottom: -20px;
        left: 0;
        width: 100%;
        background-image: url(../images/fancybox/fancybox-x.png);
        background-position: 0px -20px;
    }
    #fancybox-bg-sw {
        bottom: -20px;
        left: -20px;
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -142px;
    }
    #fancybox-bg-w {
        top: 0;
        left: -20px;
        height: 100%;
        background-image: url(../images/fancybox/fancybox-y.png);
    }
    #fancybox-bg-nw {
        top: -20px;
        left: -20px;
        background-image: url(../images/fancybox/fancybox.png);
        background-position: -40px -122px;
    }
    #fancybox-title {
        font-family: Helvetica;
        font-size: 12px;
        z-index: 1102;
    }
    .fancybox-title-inside {
        padding-bottom: 10px;
        text-align: center;
        color: #333;
        background: #fff;
        position: relative;
    }
    .fancybox-title-outside {
        padding-top: 10px;
        color: #fff;
    }
    .fancybox-title-over {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #FFF;
        text-align: left;
    }
    #fancybox-title-over {
        padding: 10px;
        background-image: url(../images/fancybox/fancy_title_over.png);
        display: block;
    }
    .fancybox-title-float {
        position: absolute;
        left: 0;
        bottom: -20px;
        height: 32px;
    }
    #fancybox-title-float-wrap {
        border: none;
        border-collapse: collapse;
        width: auto;
    }
    #fancybox-title-float-wrap td {
        border: none;
        white-space: nowrap;
    }
    #fancybox-title-float-left {
        padding: 0 0 0 15px;
        background: url(../images/fancybox/fancybox.png) -40px -90px no-repeat;
    }
    #fancybox-title-float-main {
        color: #FFF;
        line-height: 29px;
        font-weight: bold;
        padding: 0 0 3px 0;
        background: url(../images/fancybox/fancybox-x.png) 0px -40px;
    }
    #fancybox-title-float-right {
        padding: 0 0 0 15px;
        background: url(../images/fancybox/fancybox.png) -55px -90px no-repeat;
    }
    /* IE6, IE7, IE8 */
    .fancybox-ie .fancybox-bg {
        background: transparent !important;
    }
}

 @include media('<tablet') {
    .dit-nous {

        .sorting-posts {
            padding-left: 0;
            li {
                margin-bottom: 0;
                width: 100%;
                a {
                    width: 100%;
                }
            }
        }
        .results-sorting {
             padding:0 20px;

            .box {
                width: 100%;
                position: relative;
                left: auto;
                top: auto;
            }
        }
    }
}

