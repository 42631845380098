/* ==========================================================================
   #NAV
   ========================================================================== */

// Variables
/////////////////////////////

#{$nav} {
  padding:15px;
  color:$color-white;
  background:$color-blue-dark;
  width:280px;
  height:100%;
  position:fixed;
  z-index:$z-mobile-nav;
  top:55px;
  right:0;
  transform: translateX(280px);
  transition: transform 0.3s ease;

  &.is-active {
    transform: translateX(0);
  }

  .menu-mobile {
    margin-bottom:20px;

    li {
      border-bottom:1px solid $color-blue-light;
    }

    a {
      display: block;
      padding:10px 0;
    }

  }

  .lang {
    text-transform: uppercase;
  }

  .cta {

    .btn-bordered {
      margin-bottom:15px;
      display: block;
    }

  }

  .icon-facebook {
    font-size:28px;
    color:$color-white;
  }


}

/* ==========================================================================
   #NAV-MODIFIER
   ========================================================================== */

.overlay {
  position: fixed;
  z-index:$z-overlay;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background:rgba(#000,0.6);

  transition:opacity 0.3s ease, visibility 0s linear;
  visibility:hidden;
  opacity:0;
}

html.is-fixed {

  .overlay{
    visibility:visible;;
    opacity:1;
  }

}