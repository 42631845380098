/* ==========================================================================
   #CLEARFIX
   ========================================================================== */

// Ref : http://www.cssmojo.com/the-very-latest-clearfix-reloaded/

@mixin clearfix() {
  
   &:after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }
  
}