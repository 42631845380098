#{$list-post} {
  @include clearfix();
  @include center-block();
  @include padding-mobile-x();
  max-width:880px;

  @include media('>=tablet') {
    margin-top:100px;
    margin-bottom:100px;
  }

  @include media('<tablet') {
    margin-top:30px;
    margin-bottom:30px;
  }

  &__post {

    @include media('<tablet') {
      margin-top:40px;

      &:last-child {
        margin-bottom:0;
      }

    }

  }

  &__title {
    @include heading-3();
    margin-bottom:20px;
  }

  &__content {
    @include text-2();
    margin-bottom:30px;
    color:$color-blue-dark;
  }

  &__btn {}

}

#{$list-post}--col-3 {

  #{$list-post}__post {

    @include media('>=tablet') {
      lost-column: 1/3 3 60px;
    }

    @include media('<tablet') {
  
    }

  }
  
}

