
#{$box-formules} {
  @include padding-mobile-x();
  @include clearfix();
  @include center-block();
  max-width:1200px;

  &-heading {
    @include heading-1();
    position: relative;

    color:$color-white;
    background-color:$color-blue-dark;

    @include media('>=1100px') {
      padding:60px 120px;
      display: inline-block;
      top:60px;
    }

    @include media('<1100px') {
      padding:$padding-mobile;
    }

    h1 {

      @include media('>=tablet') {
        display: inline-block;
        max-width:410px;
      }

    }

    i {
      display: inline-block;
      font-size:60px;

      position: relative;
      top:0px;
      left:-20px;

      @include media('<tablet') {
        display:none;
      }

    }

  }
  
  &-inner {
    background-color: $color-gray-light;

    @include media('>=1100px') {
      padding:150px 30px 80px 30px;
      width:1060px;
      float: right;
    }

    @include media('<1100px') {

    }

  }

  .container-box-formule {
    @include clearfix();

    @include media('<1100px') {
      margin-bottom:30px;
    }

    &:nth-child(odd) {

      @include media('>=1100px') {
        margin-bottom:120px;
      }

      .box-formule {

        @include media('>=1100px') {
          left:-60px;
          float:left;
          padding-right:150px;
        }

        @include media('<1100px') {}

        .heading {
          padding-left:40px;

          &:before {
            content:attr(data-number);
            display: block;
            position: absolute;
            top:0;
            left:0;
            font-size:34px;
          }

        }

        .image {
          right:-400px;

          &:after {
            top:60px;
            left:-30px;
          }

        }   

        .btn {
          position: absolute;
          bottom:-46px;
          right:10px;
        }

      } 


    }

    &:nth-child(even) {

      @include media('>=1100px') {
        margin-bottom:140px;
      }

      .box-formule {
        text-align: right;

        @include media('>=1100px') {
          right:-60px;
          float:right;
          padding-left:150px;
        }

        @include media('<1100px') {}

        .heading {
          padding-right:50px;

          &:after {
            content:attr(data-number);
            display: block;
            position: absolute;
            top:0;
            right:0;
            font-size:34px;
          }

        }


        .image {
          left:-400px;
          top:60px;

          &:after {
            top:-30px;
            right:-30px;
            width:460px;
            height:380px;
          }

        }

        .btn {
          position: absolute;
          bottom:-46px;
          left:10px;
        }

      }


    }

    &:last-child {
      margin-bottom:0;
    }


  }

  .box-formule {

    color:$color-white;
    background-color:$color-blue-light;

    position: relative;

    @include media('>=1100px') {
      padding:30px 60px 0 60px;
      max-width:620px;
    }

    @include media('<1100px') {
      padding:30px $padding-mobile-x;
    }

    .heading {
      @include heading-2();
      margin-bottom:30px;
      color:$color-blue-dark;
      position: relative;
    }

    .text {
      @include text-default();
      font-size:12px;
      line-height:1.8;

      h1,h2,h3,h4,h5,h6 {
        font-size:15px;
        margin-bottom:14px;
      }

      p {
        margin-bottom:14px;
      }

      strong {
        font-size:15px;
      }

    }

    .image {

      @include media('>=1100px') {
        width:460px;
        position:absolute;
        z-index:10;
        top:40px;
      }

      @include media('<1100px') {
        display:none;
      }

      &:after {
        content:"";
        width:400px;
        height:350px;
        position:absolute;
        z-index:1;

        background-color: $color-blue-dark;
      }

      img,
      .btn {
        position: relative;
        z-index:10;
      }

      img {
        border:4px solid $color-blue-light;
      }

    }

  }

  .box-formule-content-text {
    position:relative;

    @include media('>=1100px') {
      top: -48px;
    }

    @include media('<1100px') {
      
    }

  }


}