/* ==========================================================================
   #list-default
   ========================================================================== */

@mixin list-dash() {
  margin-bottom:14px;

  li {
    margin-bottom:10px;
    padding-left:30px;
    position: relative;

    &:before {
      content:"";
      width:12px;
      height:1px;
      background-color:currentColor;
      position: absolute;
      top:10px;
      left:6px;
    }

    &:last-child {
      margin-bottom:0;
    }

  }

}