
#{$feature-hero} {
  @include padding-mobile-x();
  @include clearfix();
  @include center-block();
  max-width:1000px;
  position: relative;

  @include media('>=desktop') {
    margin-top:160px;
    margin-bottom:60px;
  }

  @include media('<desktop') {
    margin-top:30px;
    margin-bottom:30px;
  }

  &:before {

    @include media('>=desktop') {
      content:"";
      display: block;
      width:650px;
      height:100%;
      background: $color-gray-light;
      position: absolute;
      top:0;
      right:0;
    }
  }

  .box {
    position: relative;

    @include media('>=desktop') {
      float: right;
      width:300px;
      text-align: right;
      position: relative;
      top:-20px;
      margin-right: 40px;
      padding-bottom:30px;
    }

    @include media('<desktop') {
      padding-bottom:80px;
      text-align: left;
    }

  }

  .heading {
    @include heading-2();
    

    @include media('>=desktop') {
      margin-bottom:40px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .text {
    @include text-default();
    line-height:1.8;
    font-size:15px;

    p {
      margin-bottom:20px;

      &:last-child {
        margin-bottom:0;
      }
    }


  }

  .btn {
    margin-top:20px;
  }

  .image {

    @include media('>=desktop') {
      position: absolute;
      top:80px;
      left:0;
      z-index:10;
    }

    @include media('<desktop') {
      margin-bottom:40px;
    }

    &:after {

      @include media('>=desktop') {
        content: "";
        width: 520px;
        height: 310px;
        position: absolute;
        top: -24px;
        left: 40px;
        z-index: 1;
        background-color: #3e4855;
      }

      @include media('<desktop') {

      }


    }

    img {
      border:4px solid $color-blue-light;
      display: block;
      position: relative;
      z-index:100;

      @include media('>=desktop') {}

      @include media('<desktop') {
        @include center-block();
      }
    }

    .btn {

      @include media('>=desktop') {
        position: absolute;
        z-index:100;
        bottom: -46px;
        right: -30px;
      }

      @include media('<desktop') {
        position: absolute;
        z-index:100;
        bottom:0;
        left:20px;
      }


    }

  }

}


