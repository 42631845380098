/* ==========================================================================
   #HEADER
   ========================================================================== */

// Variables
/////////////////////////////

#{$header} {
  @include clearfix();
  padding:0;

  @include media("<large") {
    position: relative;
    z-index:100;
    background:$color-blue-light;
  }

  .col-a {

   @include media(">=large") {
      width:1120px;
      float:left;
      background:$color-blue-light;
    } 

  }
  .col-b {

    @include media(">=large") {
      width:80px;
      float:left;
    } 

  }  

  .container {
    @include clearfix();
    @include center-block();

    @include media(">=large") {
      width:1200px;
      display:flex;
    }

    @include media("<large") {
      padding:10px $padding-mobile-x;
      height:55px;
    }

  }

  .icon-facebook {

    @include media(">=large") {
      width:120px;
      float:left;
      display:block;
      margin:30px 0 0 30px;
      color:$color-white;
      font-size:20px;
    }  

    @include media("<large") {
      display:none;
    }

    &:hover {
      color:$color-blue-dark;
    }

  }

  .logo {
    display: block;
    width: 148px;

    @include media(">=large") {
      margin:30px 20px 0 0;
      float:left;
    }  

    @include media("<large") {
      width:70px;
      float:left;
    }

  }

  .container-nav {

    @include media(">=large") {
      margin-right:30px;
      margin-bottom:30px;
      padding:60px 0 0 0;
      width:730px;
      float:right;
      position: relative;
    } 

  }

  .nav-secondary {
    @include clearfix();

    @include media(">=large") {
      position: absolute;
      top:0;
      right:0;
    }  

    @include media("<large") {
      display:none;
    }

    li {
      margin-right:20px;
      float:left;

      &:last-child {
        margin-right:0;
      }

    }

    a {
      padding-top:20px;
      font-size: 12px;
      color: $color-blue-dark;
      text-decoration: underline;
      display: block;

      &:hover {
        color:$color-white;
      }


    }

    .lang {
      padding:0 10px 5px 10px;
      background-color:$color-white;

      a {
      display: inline-block;
      color:$color-blue-light;
      text-decoration:none;
      text-transform: uppercase;

      &:hover {
        color:$color-blue-dark;
      }



      } 

    }

  }

  .nav-main {

    @include media(">=large") {
      @include galanoclassic-bold();
      margin-top:10px;
      float:left;
      color:$color-white;
      font-size: 13px;
      line-height: 1;
      text-transform: uppercase;
    }  

    @include media("<large") {
      display:none;
    }

    li {
      margin-right:20px;
      float:left;

      &:after {
        content:"";
        margin-left:20px;
        display: inline-block;
        background-color:$color-white;
        width:5px;
        height:5px;
        border-radius:50%;
        position:relative;
        top:-3px;
      }

      &:last-child {
        margin-right:0;

        &:after {
          margin-left:0;
          display:none;
        }

      }

    }

    a {
      padding:5px;

      &:hover {
        color:$color-blue-dark;
      }

    }

  }

  .cta {
    
    @include media(">=large") {
      float:right;
    }  

    @include media("<large") {
      display:none;
    }

    a {
      padding-top:10px;
      padding-bottom:10px;
      display: block;
      margin-bottom:10px;

      &:last-child {
        margin-bottom:0;
      }

    }


  }

  .france {

    @include media(">=large") {
      background-color:$color-white;
      padding: 16px 5px 5px 5px;
      text-align: center;

      i {
        position: relative;
        top:1px;
        
      }
    }  

    @include media("<large") {
      display:none;
    }

    a {
      color:$color-blue-light;
      font-size:12px;
      line-height:1;

      &:hover {
        color:$color-blue-dark;
      }
      
      span {
        text-decoration:underline;
      }

    }


  }

  .hamburger {

    @include media(">=large") {
      display:none;
    }

    @include media("<large") {
      margin-top:5px;
      float:right;
    }

  }



}

/* ==========================================================================
   #HEADER-MODIFIER
   ========================================================================== */
