/* ==========================================================================
   #VU-MEDIA
   ========================================================================== */

// Variables
/////////////////////////////

#{$vu-media} {
  @include padding-mobile-x();
  padding-top:60px;
  padding-bottom:60px;
  position: relative;

  &:after {

    @include media('>=tablet') {
      content:"";
      display: block;
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
      height:96px;
      background-color:#efefef;
    }

  }

  .heading {
    margin-bottom:50px;
    text-align: center;
  }

  .medias-wrapper {
    position: relative;
    padding:0 70px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    height: 24px;
    width: 15px;
  }

  .swiper-button-prev {
    background: url(../images/swiper-arrow-left.png) no-repeat;
  }

  .swiper-button-next {
    background: url(../images/swiper-arrow-right.png) no-repeat;
  }

}

.swiper-container-vu-medias {

  img {
    @include center-block();
  }

}

/* ==========================================================================
   #WYSIWYG-MODIFIER
   ========================================================================== */

/* ==========================================================================
   #WYSIWYG-STYLE
   ========================================================================== */