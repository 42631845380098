
#{$content-tab} {
  @include clearfix();
  @include center-block();
  max-width:1200px;
  position: relative;
  z-index: 100;

  @include media('>=large') {
    margin-bottom:130px;
  }

  @include media('<large') {
    max-width: 800px;
    margin-bottom:30px;
    padding: 0 20px;
  }

  &.top {

    @include media('>=large') {
      margin-top:-90px;
    }

    @include media('<large') {

    }

  }

  .container-nav-tabs {
    @include clearfix();

    @include media('>=large') {
      width:760px;
    }

    @include media('<large') {

    }

  }

  .nav-tabs {

    @include media('>=large') {
      float: right;
      display:flex;
    }

    @include media('<large') {

    }

    li {
      background-color:$color-blue;

      @include media('>=large') {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-right:4px;
      }

      @include media('<large') {}

      &:hover {
        background-color:$color-blue-light;

        a {
           color:$color-white;
        }
       
      }

      &.is-active {
        background-color:$color-blue-light;

        a {
           color:$color-black;
        }

      }

      &:last-child {
        margin-right:0;
      }
    }

    a {
      @include galanoclassic-bold();
      padding:8px 10px;
      font-size:11px;
      
      color:$color-black;
      display: block;
      text-align: center;
      text-transform: uppercase;

      @include media('>=large') {
        max-width:150px;
      }

      @include media('<large') {}


    }

  }

  .tab-pane {
    display:none;

    &.is-active {
      display: block;
    }
  }

  .block-image {

    background:$color-blue-light;
    position: relative;
    z-index:30;

    @include media('>=large') {
      padding:50px 70px 0 70px;
      width:760px;
      min-height:550px;
      float:left;
    }

    @include media('<large') {
      padding:30px 20px;
    }

    img {
      @include center-block();

      @include media('>=large') {
        margin-bottom:-70px;
      }

      @include media('<large') {
        max-width:300px;
      }

    }

  }

  .block-text {

    background-color:$color-gray-light;
    
    font-size:16px;
    color:$color-blue-dark;

    position: relative;
    z-index:10;

    @include media('>=large') {
      float:left;
      width:440px;
      margin-top:40px;
      padding:80px 70px;
    }

    @include media('<large') {
      padding:30px 20px;
    }

    &:before {
      content:"";
      display: block;
      width:170px;
      height:100%;
      background-color:$color-gray-light;
      position: absolute;
      top:0;
      left:-170px;
    }

    h2,h3,h4,h5,
    p {
      margin-bottom:20px;

      &:last-child {
        margin-bottom:0;
      }
    }

    ul {
      list-style-type:square;

      @include media('<large') {
        margin:0 20px;
      }

      li {
        margin-bottom:14px;

        &:last-child {
          margin-bottom:0;
        }
      }
    }

  }


}


#{$content-tab}.right {
  position: relative;

  @include media('>=large') {
    margin-top:-40px;
    margin-bottom:80px;
  }

  .nav-tabs  {

    @include media('>=large') {
      transform: rotate(-90deg);
      position:absolute;
      bottom: 280px;
      right: 388px;
      z-index:100;
    }

  }

  .block-image {

    @include media('>=large') {
      margin-top:40px;
      padding:12px;
      width:530px;
      min-height:700px;
      float: right;
      position: relative;
      left:-80px;
    }

  }

  .block-text {

    color:$color-white;
    background-color:$color-blue-dark;

    @include media('>=large') {
      margin:0;
      width:600px;
      min-height:670px;
      padding-left:120px;
      padding-right:120px;
    }
    

    &:before {
      display:none;
    }

  }


}