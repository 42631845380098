/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

*,
*:after,
*:before {
  box-sizing: border-box;
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
}

/* ==========================================================================
   #HTML
   ========================================================================== */

html {
  overflow-x: hidden; // for Full-width element in fixed-width container
  //overflow-y: scroll; /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%; /* iOS 8+ */
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

/* ==========================================================================
   #BODY
   ========================================================================== */

body {
  line-height:1;
}

/* ==========================================================================
   #RESET-TAGS
   ========================================================================== */

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var,
figure,
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

// list
ul,
ol {
  list-style: none;
}

// heading
h1,
h2,
h3,
h4,
h5,
h6 {}

// link
a {
  color: inherit;
  outline: none;
  text-decoration: none;
  
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
  
  &:active,
  &:hover {
    outline-width: 0; /* Remove the outline when hovering in all browsers */
  }
  
}

// images
img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
}

// table
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// strong
b,
strong {
  font-weight: bolder; /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}

/* ==========================================================================
   #FROM
   ========================================================================== */

input {
  border-radius: 0;
}

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type="number"] {
  width: auto; /* Firefox 36+ */
}

[type="search"] {
  -webkit-appearance: textfield; /* Safari 8+ */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Safari 8 */
}

textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
  font: inherit; /* Specify font inheritance of form elements */
}

optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule. */
}

button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type="button"], /* Prevent a WebKit bug where (2) destroys native `audio` and `video`controls in Android 4 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}

button,
select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

/* Style select like a standard input */
select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}

select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}

legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}

[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}

/* ==========================================================================
   #SELECTION
   ========================================================================== */

/* Specify text selection background color and omit drop shadow */

::-moz-selection {
  background-color: $text-selection-bg; /* Required when declaring ::selection */
  color: $text-selection-color;
  text-shadow: none;
}

::selection {
  background-color: $text-selection-bg; /* Required when declaring ::selection */
  color: $text-selection-color;
  text-shadow: none;
}