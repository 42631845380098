/* ==========================================================================
   #btn-reset
   ========================================================================== */

@mixin btn-reset() {
  -webkit-appearance: none;
  -webkit-box-align: center;
  user-select:none; 
  line-height:1;
}

/* ==========================================================================
   #btn-reset
   ========================================================================== */

@mixin btn-base() {
  @include btn-reset();
  padding:11px 14px;
  display:inline-block;

  font-size:14px;
  transform: none;

  //transition: all $transition-speed-medium ease;
  font-family: $galanoclassic-bold;
  text-transform: uppercase;
}

/* ==========================================================================
   #BTN
   ========================================================================== */

#{$btn}-bordered {
  @include btn-base();

  color:$color-white;
  border:2px solid currentColor;

  &:hover {
    color:$color-blue-dark;
    background-color:$color-white;
  }

  &#{$btn}--icon {
    position: relative;
    padding-right:50px;
    
    i {
      position: absolute;
      top:50%;
      right:12px;
      transform: translateY(calc(-50% - 2px));
      font-size:17px;
    }

  }

}

#{$btn}--blue-light {
  @include btn-base();
  background-color:$color-blue-light;
  color:$color-black;

  &:hover {
    color:$color-white;
    background-color:#616974;
  }

}

#{$btn}--blue-light2 {
  @include btn-base();
  background-color:#82bac6;
  color:$color-black;

  &:hover {
    color:$color-white;
    background-color:#616974;
  }

}

#{$btn}--blue {
  @include btn-base();
  background-color:$color-blue-dark;
  color:$color-white;

  &:hover {
    color:$color-white;
    background-color:#616974;
  }

}


#{$btn}--icon {
    position: relative;
    padding-right:70px;
    
    i {
      position: absolute;
      top:50%;

      @include media('<tablet') {
        text-align: center;
      }

    }

}

#{$btn}--med {
  padding:20px 36px;
  font-size:16px;

  @include media('<tablet') {
    padding-left:18px;
    padding-right:18px;
  }

  i {
    right:38px;
    transform: translateY(calc(-50% - 1px));
    font-size:21px;
  }

  &.btn--icon {
    padding:15px 72px 15px 20px;

    @include media('<tablet') {
      padding-left:18px;
    }

  }

}

#{$btn}--big {
  padding:20px 36px;
  font-size:16px;

  @include media('<tablet') {
    padding-left:18px;
    padding-right:18px;
  }

  &.btn--icon {
     padding:20px 72px 20px 20px;

    @include media('<tablet') {
      padding-left:18px;
    }

  }

  i {
    right:38px;
    transform: translateY(calc(-50% - 1px));
    font-size:21px;
  }



}

