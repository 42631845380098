/* ==========================================================================
   #HTML-BODY
   ========================================================================== */
body, 
html {
  height:100%;
  min-width: 320px;
  max-width:100vw;
}

/* ==========================================================================
   #HTML
   ========================================================================== */

html {
  overflow-y: scroll;

  &.is-fixed {
    overflow:hidden;
    position:fixed;
  }
}

/* ==========================================================================
   #BODY
   ========================================================================== */

body {
  @include galanoclassic-medium();
  color:$color-black;
  background-color:$color-white;

  line-height:$font-text-line-height;
  word-break: normal;
  word-wrap: break-word;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}













