/* ==========================================================================
   #GRID
   Framework : LOST
   Doc : http://lostgrid.org/docs.html
   Example : http://lostgrid.org/lostgrid-example.html
   ========================================================================== */

// grid
/////////////////////////////
.o-grid {}

// columns
/////////////////////////////
@for $i from 2 through 12 {

    .o-grid-#{$i} {

        & > .o-grid-col {
            lost-column: 1/#{$i} 0 20px;
        }

    }
}



