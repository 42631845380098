
#{$box-content} {
  @include center-block();
  @include text-1();
  position: relative;
  background-color:$color-gray-light;

  @include media('>=large') {
    margin-top:-30px;
  }

  @include media('>=desktop') {
    padding:60px 80px;
    max-width:760px;
  }

  @include media('<desktop') {
    margin-bottom:20px;
    max-width:760px;
    padding:30px 20px;
  }

  h1,h2,h3,h4,h5,h6 {
    color: $color-blue-light;
    margin-bottom:20px;
  }

  h1 {
    @include heading-1();
  }

  h2 {
    @include heading-2();

  @include media('>=tablet') {
      font-size: 30px;
  }

  @include media('<tablet') {
      font-size: 20px;
  }

  }

  h3 {
    @include heading-3();
  }

  h4 {
    @include heading-4();
  }

  h5 {
    @include heading-5();
  }

  h6 {
    @include heading-6();
  }

  p, ul {
    margin-bottom:20px;
  }

  p {

    &:last-child {
      margin-bottom:0;
    }

  }

  ul {
    @include list-dash();
  }  

}

#{$box-content}--media {

  @include media('>=tablet') {
    padding-bottom:100px;
  }

  @include media('<tablet') {
    
  }

}

#{$box-content}--blue-dark {
  background-color:$color-blue-dark;
  color:$color-white;
}

#{$box-content}-media {
  @include padding-mobile-x();
  @include center-block();
  position: relative;
  max-width:760px;

  @include media('>=desktop') {
    top:-60px;
    left:-100px;
    z-index:10;
  }

  @include media('<desktop') {
    margin-top:20px;
    margin-bottom:30px;
  }

  .video-container {
    position: relative;
    padding-bottom: 52%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  .text {
    @include text-1();
    
    line-height:1.6;
    padding:30px;
    background-color:$color-blue-dark;
    color:$color-white;

    @include media('>=desktop') {
      width:340px;
      position: absolute;
      bottom: 50px;
      right: -180px;
      z-index:10;
    }

    @include media('<desktop') {
      
    }

    a {
      text-decoration:underline;
    }

  }

}

#{$box-content}--padding-bottom-large {

  @include media('>=desktop') {
    padding-bottom:130px;
  }

}


#{$box-content}--intro {

  color:$color-blue-light;

  @include media('>=desktop') {
    font-size:24px;
    line-height:1.4;
    padding-right:200px;
  }

  @include media('<desktop') {
    font-size:14px;
  }

}