.c-box-important {
  @include clearfix();
  @include center-block();
  max-width:1200px;

    @include media('>=desktop') {}

    @include media('<desktop') {
      @include padding-mobile-x();
    }

  .col-a {
    background-color:$color-blue-dark;

    .title {
      color: $color-blue-very-light;
    }

    @include media('>=desktop') {
      padding:120px;
      width:50%;
      float:left;
    }

    @include media('<desktop') {
      padding:30px 20px;
       margin-bottom:20px;
    }

  }

  .col-b {

    position:relative;
    z-index:10;

    @include media('>=desktop') {
      width:50%;
      float:left;
    }

    @include media('<desktop') {
  
    }

  }

  .title {
    @include heading-2();

    @include media('>=desktop') {
      margin-bottom:50px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .text {
    font-size:14px;
    line-height:1.8;
    color:$color-white;
  }

  .image {
    border: 6px solid $color-blue-light;
    display: block;

    @include media('>=desktop') {
      position:relative;
      top:30px;
      left:-50px;
    }

    @include media('<desktop') {
      @include center-block();
       margin-bottom:20px;
    }

  }

  .desc {
    font-size:14px;
    line-height:1.8;
    color:$color-black;
    
    @include media('>=desktop') {
      margin-top:60px;
      margin-bottom:50px;
      margin-left:50px;
      width:420px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

    b,
    strong {
      font-family: $galanoclassic-bold;
    }

  }

  .container-btn {

    @include media('<desktop') {
      text-align:center;
    }

  }

  .btn {
    position:relative;

    @include media('>=desktop') {
      top:-20px;
      left:-75px;
    }

    @include media('<desktop') {}

  }

}