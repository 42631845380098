
// 1.settings
///////////////////////////////////////////
@import '1.settings/variables';
@import '1.settings/selectors';
@import '1.settings/include-media';
@import '1.settings/lost';
@import '1.settings/hamburger';
@import 'icomoon/variables';
@import 'icomoon/style';

@mixin galanoclassic-bold() {
    font-family: $galanoclassic-bold;
    font-weight: normal;
    font-style: normal;
}

@mixin galanoclassic-semibold() {
    font-family: $galanoclassic-semibold;
    font-weight: normal;
    font-style: normal;
}

@mixin galanoclassic-medium() {
    font-family: $galanoclassic-medium;
    font-weight: normal;
    font-style: normal;
}



// 2.tools - functions
///////////////////////////////////////////
@import '2.tools/functions/map';

// 2.tools - mixins
///////////////////////////////////////////
@import '11.vendors/include-media';

// General
@import '2.tools/mixins/animation';
@import '2.tools/mixins/transition';

// Helpers
@import '2.tools/mixins/helpers/pseudo';
@import '2.tools/mixins/helpers/center-block';
@import '2.tools/mixins/helpers/clearfix';
@import '2.tools/mixins/helpers/input-placeholder';
@import '2.tools/mixins/helpers/responsive-ratio';
@import '2.tools/mixins/helpers/text-truncate';
@import '2.tools/mixins/helpers/triangle';
@import '2.tools/mixins/helpers/embed';
@import '2.tools/mixins/helpers/mobile';

// Elements
@import '2.tools/mixins/elements/btn';
@import '2.tools/mixins/elements/links';
@import '2.tools/mixins/elements/icons';
@import '2.tools/mixins/elements/heading';
@import '2.tools/mixins/elements/text';
@import '2.tools/mixins/elements/list';


// Components

// 3.base
///////////////////////////////////////////
@import '3.base/reset';
@import '3.base/fonts';
@import '3.base/typography';
@import '3.base/global';
@import '3.base/icons';

// 4.elements
///////////////////////////////////////////
@import '4.elements/btn';

// 5.components
///////////////////////////////////////////
@import '5.components/vu-medias';
@import '5.components/blockquotes-section';
@import '5.components/box-content';
@import '5.components/list-post';
@import '5.components/feature-services';
@import '5.components/feature-hero';
@import '5.components/feature-hero-home';
@import '5.components/feature-expertise';
@import '5.components/box-formules';
@import '5.components/demandez-estimation';
@import '5.components/distinctions';
@import '5.components/page-headline';
@import '5.components/slider-hero';
@import '5.components/box-important';
@import '5.components/block-news';
@import '5.components/content-tab';
@import '5.components/dit-nous';

// 6.objets
///////////////////////////////////////////
@import '6.objets/container';
@import '6.objets/grid';

// 7.layout
///////////////////////////////////////////
@import '7.layout/wrapper';
@import '7.layout/header';
@import '7.layout/nav';
@import '7.layout/content';
@import '7.layout/footer';

// 8.pages
///////////////////////////////////////////
@import '8.pages/404';
@import '8.pages/about-services';
@import '8.pages/promo';
@import '8.pages/nous-joindre';
@import '8.pages/nous-recrutons';
@import '8.pages/entretien-maison';

// 9.themes
///////////////////////////////////////////

// 10.utils
///////////////////////////////////////////
@import '10.utils/mq-status';
@import '10.utils/mq-visibility';

// 11.vendors
///////////////////////////////////////////
@import '11.vendors/hamburgers/hamburgers';
@import '11.vendors/aos/aos';

// Shame
///////////////////////////////////////////
@import 'shame';



