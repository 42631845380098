#{$slider-hero} {
  @include center-block();
  position:relative;
  background-color:$color-blue-light;
  position:relative;

  @include media('>=large') {
    margin-bottom:220px;
    max-width:1200px;
    height:calc(450px - 90px);
  }

  @include media('<large') {
    margin-bottom:30px;
  }

  .logo-conso {
    position: absolute;
    top:0;
    z-index:100;
    width:88px;

    @include media('>=large') {
      left:20px;
    }

    @include media('<large') {
      left:20px;
    }

    @include media('<phone') {
      display:none;
    }

  }

  &__headline {
    color: #ffffff;
    
    font-weight: 600;
    line-height: 1.1;

    position: absolute;

    z-index:100;
    text-shadow: 1px 1px 16px rgba(#000,0.6); 

    @include media('>=large') {
      left:90px;
      top:120px;
      font-size: 60px;
      width: 150px;
    }

    @include media('<large') {
      font-size: 60px;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      width:90%;
      text-align: center;
    }

    @include media('<tablet') {
      font-size: 40px;
    }

    @include media('<phone') {
      font-size: 20px;
    }

  }


  .swiper-container-banner {

    @include media('>=large') {
      margin:0 0 0 120px;
      width:1080px;
      position: relative;
      top:0;
    }

    @include media('<large') {}

  }

  .swiperPaginationBanner {

    @include media('>=large') {
      position:absolute;
      bottom:90px;
      left:55px;

      width:10px;
    }

    @include media('<large') {

    }

    .swiper-pagination-bullet {
      width:10px;
      height:10px;
      background-color:$color-white;
    }

  }

  .c-demandez-estimation-slider {

    @include media('>=large') {
      position: absolute;
      bottom:calc(-110px);
      right:120px;
    }

    @include media('<large') {

    }

  }

}