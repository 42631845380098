/*BLOC Nous recrutons*/

.nous-recrutons {
  margin-top:40px;
  @include center-block();
  max-width:960px;

    .row {
      @include clearfix();
    }

    .recrutons-contenu {
        padding-left: 20px;
        padding-right: 20px;
    }

    .col-6 {
      lost-column: 1/2 2 40px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 1.4em;
        font-family: $galanoclassic-medium;
    }
    
    p {
      margin-bottom:20px;
        font-size: 0.875em;
    }
    ul {
      @include list-dash();
        padding-left: 20px;
        li {
            font-size: 0.875em;
            margin-bottom: 5px;
        }
    }
    .recrutons-intro {
        padding-top: 40px;
    }
    .rectrutons-coordonnees {
        h3 {
            font-size: 0.875em;
            font-family: $galanoclassic-semibold;
        }
        .btn {
            @extend .btn--blue-light;
            @extend .btn--med;
        }
    }
}

@include media('<tablet') {
    .nous-recrutons {
        padding-bottom: 20px;
        &.container {
            .wrapper {
                h1 {
                    margin-bottom: 25px;
                    margin-top: 0;
                }
                h2 {
                    font-size: 1.5em;
                    margin-bottom: 10px;
                }
                .recrutons-intro {
                    padding-top: 15px;
                }
                .rectrutons-coordonnees {
                    .btn {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}