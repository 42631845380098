.block-news {
  @include center-block();
  max-width:900px;

  @include media('>=desktop') {
    margin-top:70px;
    margin-bottom:70px;
  }

  @include media('<desktop') {
    @include padding-mobile-x();
    margin-top:40px;
    margin-bottom:40px;
  }

  .title-section {
    @include heading-1();
    text-align: center;

    @include media('>=desktop') {
      margin-bottom:40px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

  }

  .container {
    @include clearfix();
    @include padding-mobile-x();
  }

  .item {

    @include media('>=desktop') {
      lost-column: 1/3 3 40px;
    }

    @include media('<desktop') {
      margin-bottom:20px;
    }

    &:last-child {
      margin-right:0;
    }


  }

  .date {
    @include galanoclassic-bold();
    margin-bottom:20px;
    padding:12px 16px;
    display: inline-block;
    color:$color-white;
    background-color:$color-blue-dark;
    font-size:13px;
    line-height:1;
  }

  .title {
    @include galanoclassic-bold();
    color:$color-blue-dark;
    font-size: 19px;
    line-height: 1.4;
    margin-bottom:30px;
  }

  .text {
    font-size: 14px;
    line-height: 1.8;
    color:$color-black;
  }

  .box-link {
    text-align: center;

    @include media('>=desktop') {
      margin-top:60px;
    }

    @include media('<desktop') {
      margin-top:30px;
    }

  }

}