/*BLOC Entretien regulier*/

.entretien-regulier{

	.wrapper{
		padding-left: 20px;
		padding-right: 20px;
	}	
	.description{
		margin-bottom: 35px;
		ul{
			padding-right: 20px;
			li{
				font-size: 0.875em;
				font-family: $galanoclassic-medium; 
				color: #414141;
				padding-bottom: 10px;
			}
		}
		.intro-entretien{
			p{
				color: #414141;
				font-size: 1em;
				font-family: $galanoclassic-bold; 
				margin-bottom: 45px;
			}
		}
		.contenu-entretien{
			p{
				color: #414141;
			}
			li{
				font-size: 0.875em;
			}
		}
	}
	.expertise{
		background-color: #f2f2f2;
		.wrapper{
			padding-top: 35px;
			padding-bottom: 35px;
			@media screen and (min-width: 768px){
				.column{
					padding-left: 30px;
					padding-right: 30px;
				}
			}
			.column:nth-child(1){
				&:after{
					content: " ";
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width:11px;
					background: url('../images/recrutons-separateur.png') center no-repeat;
				}
			}
			h1{
				margin-bottom: 40px;
				margin-top: 0;
			}
			h2{
				color: $color-blue; 
				font-size: 1.375em;
			}
			p{
				color: #414141;
				font-family: $galanoclassic-medium;
				font-size: 0.875em;
			}
		}
	}
	/*  NAVIGATION HOUSE MODULE */
	#mappingNavigation,
	#mappingNavigation area{
		border: none;
	}
	.navigation_house{
		position: relative;
		margin-top: 50px;
		border: none;
	}
	.navigation_house img{
		width: 100%;
		border: none;
	}
	.navigation_house > div[id^=layer_] {
		opacity: 1;
		filter: alpha(opacity=100) !important;
		-webkit-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
	.navigation_house > div[id^=layer_].active {
		opacity: 0;
	}
	.navigation_house #layer_complet,
	.navigation_house #layer_1,
	.navigation_house #layer_2, 
	.navigation_house #layer_3, 
	.navigation_house #layer_4, 
	.navigation_house #layer_5, 
	.navigation_house #layer_6, 
	.navigation_house #layer_7, 
	.navigation_house .layer_navigation{
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0;
		border: none;
	}
	.navigation_house .active{
		opacity: 0 !important;
	    filter: alpha(opacity=0) !important;
	}
	.return_top{
		position: absolute;
		z-index: 5;
		bottom: 0;
		right: 0;
		color: #fff;
		text-decoration: none;
	}
	/* nav tabs styles */
	.tadset{

    @include media('<tablet') {
      padding:0 20px;
    }

		&.nav-tabs{
    border-bottom: none;


    @include media('>=tablet') {
    display: table;
    
    }

    @include media('<desktop') {

    }

			li{
        padding: 5px;


        @include media('>=tablet') {
        display: table-cell;
        width: 20%;
        }

        @include media('<tablet') {
          display: block;
          
        }


			}
		}
	}

 .tab-content>.tab-pane{display:none;visibility:hidden}.tab-content>.active{display:block;visibility:visible}

	.tab-content .description{
		padding: 2px 0 0;
		font:14px/17px 'baskerville_old_faceregular', 'times new roman', serif;
	}
	.img-container img{
		display:block;
		width: 100%;
		height:auto;
    margin:0 auto;
	}


/* nav tabs styles */
.tadset{	
	margin: 19px 0 0;
	&.after{
		display:none;
	}
	&.nav-tabs{
		border-bottom: none;
		display: table;
		width: 100%;
		li{
			padding: 5px;
			display: table-cell;
  			
			&.active{
				a{
					background-color: $color-blue; 
					color: white;
				}
			}
		}
		a{
      display: block;
			background-color: #e0e0e0;
			border-radius: 0;
			border-style:none !important;
			color: #414141;
			font-size: 0.813em;
			font-family: $galanoclassic-bold;
			height: 47px;
			margin-right: 0;
			padding: 0 10px;
			text-align: center;
			text-decoration:none;
			text-transform: uppercase;
			&:hover, &:active{
				background-color: $color-blue; 
				color: white;
			}
			&:before{
				content: "";
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}
			span{
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
.tab-content{
  @include clearfix();
	padding: 35px 0 18px 0;
	margin: 0 0 25px;

  @include media('<desktop') {
    margin-bottom:20px;
    padding:0 20px;
  }

  .list-services {
    margin-left:20px;
    list-style-type:square;
  }


}
.tab-content .list-services .icon-ico10{
	position:absolute;
	left:0;
	top: 3px;
	font-size: 13px;
	margin: 0;
}
.tab-content .box-visuales{padding: 0;}
.tab-content .box-visuales.house_nav{
	padding-bottom:35px;
}
.tab-content .img-container{


  @include media('>=tablet') {
    width:25%;
    float:left;
    	padding: 0 18px 0 0;
  }

  @include media('<tablet') {
    margin-bottom:20px;

  }

}
.tab-content .img-container a{
	padding: 2px 4px 5px 3px;
	display:block;
}
.tab-content .description{
	padding: 2px 0 0;


  @include media('>=desktop') {
    width:75%;
    float:left;
  }

  @include media('<desktop') {

  }


}










}

@media screen and (max-width: 767px){
	.entretien-regulier{
		
		.description{
			margin-bottom: 0;
		}
		.expertise{
			.wrapper{
				padding-bottom: 0;
				h1{
					margin-bottom: 20px;
				}
				h2{
					margin-bottom: 15px;
				}
				.column{
					margin-bottom: 30px;
					
				}
			}
		}
		.demandez-estimation{
			.wrapper{
				max-width: 300px;
				padding-top: 0;
				padding-bottom: 20px;
				.column{
					&.gauche{
						.pull-right{
							float: none !important;
						}
					}
					&.droite{
						.holder{
							.btn{
								top:0;
								padding-right: 45px;
							}
						}
					}
				}
			}
		}
		.tab-content{
			padding-bottom: 0;
			padding-top: 15px;
			.box-visuales{
				&.house_nav{
					padding-bottom: 0;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 979px){
	
}


