

#{$page-headline} {
  @include center-block();

  @include media(">=large") {
    width:1200px;
  }

  .wrapper {
    background-color:$color-blue-light;

    @include media(">=large") {
      width:1120px;
    }

    @include media("<large") {
      padding:30px $padding-mobile-x;
    }

  }
  
  .container {

    @include media(">=large") {
      margin-left:120px;
      padding:0 0 40px 30px;
    }

    @include media("<large") {

    }

  }

  h1 {
    @include galanoclassic-semibold();

    color:$color-blue-dark;
    background-color: $color-gray-light;
    display: block;
    line-height:1.2;

    @include media(">=large") {
      font-size:60px;
      position: relative;
      left:0px;
      width:calc(100% + 80px);
      padding:60px 90px;
    }

    @include media("<large") {
      padding:20px 15px;
      font-size:30px;
    }

  }

  .content {
    color: $color-blue-dark;
    font-size: 18px;
    line-height: 1.8;

    @include media(">=large") {
      @include center-block();
      margin-top:40px;
      width: 680px;
    }

    @include media("<large") {
      padding-top:20px;
    }

  }

}

#{$page-headline}--padding-bottom-large {

  .container {

    @include media(">=large") {
      padding-bottom:80px;
    }
    
  }

  h1 {

    @include media(">=large") {
      padding:100px 150px 80px 90px;
    }

  }

}

#{$page-headline}--blue-dark {

  h1 {
    background-color: $color-blue-dark;
    color:$color-white;
  }

}