/* ==========================================================================
   #text-1
   ========================================================================== */
@mixin text-1() {
  font-size:15px;
  line-height:1.8;
}

/* ==========================================================================
   #text-2
   ========================================================================== */
@mixin text-2() {
  font-size:14px;
  line-height:1.6;
}

/* ==========================================================================
   #text-default
   ========================================================================== */
@mixin text-default() {
  font-size:14px;
  line-height:1.5;
}

