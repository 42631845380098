/* ==========================================================================
   #FOOTER
   ========================================================================== */

// Variables
/////////////////////////////

#{$footer} {
  padding:50px 0 0 0;

  color:$color-white;
  background-color: #333b39;

  /* footer-nav */
  .footer-nav {
    @include padding-mobile-x();
    margin-bottom:50px;

     @include media('>=tablet') {
        margin-bottom:32px;
      }

      @include media('<tablet') {
        margin-bottom:38px;
      }

  }

  .list-pages {
    @include clearfix();
    font-size:16px;

    li {

      @include media('>=tablet') {
        margin-bottom:18px;
        float: left;
        width:50%;
      }

      @include media('<tablet') {
        margin-bottom:12px;
        text-align:center;
      }

    }

    a {

      &:hover {
        color:$color-blue-light;
      }
      
    }

  }

  /* footer-info */
  .footer-info {
    padding:30px 0; 
    background-color: #4a5351;

    @include media('<tablet') {
      text-align:center;
    }

    .text {
      display: inline-block;
      font-size:14px;

      @include media('>=tablet') {
        margin-right:40px;
      }

      @include media('<tablet') {
        display: block;
        margin-bottom:20px;
      }

    }

    .phone {
      @include galanoclassic-bold();
      font-size:26px;
      vertical-align: middle;

      @include media('>=tablet') {
       margin-right:40px;
      } 

      @include media('<tablet') {
        display: block;
        margin-bottom:20px;
      }

    }

    .btn-soumission {}


  }

}

/* ==========================================================================
   #FOOTER-MODIFIER
   ========================================================================== */


