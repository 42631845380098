
/* ==========================================================================
   #hamburger
   ========================================================================== */

$hamburger-padding-x:                        0px;
$hamburger-padding-y:                        0px;
$hamburger-layer-width:                      40px;
$hamburger-layer-height:                     2px;
$hamburger-layer-spacing:                    9px;
$hamburger-layer-color:                      $color-white;
$hamburger-layer-border-radius:              0px;
$hamburger-hover-opacity:                    0.8;
$hamburger-hover-transition-duration:        0.15s;
$hamburger-hover-transition-timing-function: ease;

$hamburger-layer-width-mobile:               28px;
$hamburger-layer-height-mobile:              2px;
$hamburger-layer-spacing-mobile:             7px;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter:                 false;
$hamburger-hover-filter:                     opacity(50%);

$header-height:                              60px;
$header-height-mobile:                       46px;