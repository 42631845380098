/* ==========================================================================
   #DEMANDEZ-ESTIMATION
   ========================================================================== */

// Variables
/////////////////////////////

#{$demandez-estimation} {
  @include center-block();
  max-width:1120px;
  position: relative;

  @include media('>=tablet') {
    margin-top:60px;
    margin-bottom:60px;
    height:240px;
    background: url('../images/bg-estimation.jpg') 0 center no-repeat;
    background-size: cover;
  }

  @include media('<tablet') {
    @include padding-mobile-x();
    margin-top:30px;
    margin-bottom:30px;
  }

  .box {
    color:$color-white;

    font-size:14px;

    @include media('>=tablet') {
      padding:40px 32px 30px 32px;
      position: absolute;
      z-index:10;
      min-width:500px;
      bottom:-40px;
      right:60px;
    }

    @include media('<tablet') {
      padding:$padding-mobile-x;
      background-color:$color-blue-dark;
      text-align: center;
    }

    &:after {
      content:"";

      background-color:$color-blue-dark;

      @include media('>=tablet') {
        z-index:-1;
        position: absolute;
        top:0;
        left:0;
        width:420px;
        height:100%;
      }

    }

  }

  .estimation-title {
    margin-bottom:16px;
  }

  .estimation-phone {
    font-size: 18px;

    @include media('>=tablet') {
      display: inline-block;
      margin-right:14px;
    }

    @include media('<tablet') {
      display: block;
    }

  }

  .estimation-btn {

    @include media('<tablet') {
      margin-top:14px;
    }

  }

}

#{$demandez-estimation}-slider {
  position: relative;
  z-index:50;

  .box {
    position: relative;
    color:$color-white;
    background-color:$color-blue-dark;

    font-size:14px;

    @include media('>=large') {
      padding:50px 32px 30px 32px;
      width:500px;
    }

    @include media('<large') {
      padding:$padding-mobile-x;
      background-color:$color-blue-dark;
      text-align: center;
    }

  }

  .estimation-title {
    margin-bottom:16px;
  }

  .estimation-phone {
    font-size: 18px;

    @include media('>=large') {
      display: inline-block;
      margin-right:14px;
    }

    @include media('<large') {
      display: block;
    }

  }

  .estimation-btn {
    font-size:12px;

    @include media('<tablet') {
      margin-top:14px;
    }

  }

  .btns {

    @include media('>=large') {
      width:300px;
      position: absolute;
      top:-60px;
      right:15px;
    }

    @include media('<large') {
      margin-bottom:20px;
    }

    a {
      display: block;
      
      @include media('>=large') {
        margin-bottom:2px;
      }

      @include media('<large') {
        margin-bottom:10px;
      }

    }

  }
  
}


/* ==========================================================================
   #WYSIWYG-MODIFIER
   ========================================================================== */

/* ==========================================================================
   #WYSIWYG-STYLE
   ========================================================================== */